import { faFacebook, faGithub, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faDownload, faSave, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loremIpsum } from "react-lorem-ipsum";
import pic1 from "$images/pic1.jpg";
import pic2 from "$images/pic2.jpg";
import pic3 from "$images/pic3.jpg";
import pic4 from "$images/pic4.jpg";
import pic5 from "$images/pic5.jpg";
import pic6 from "$images/pic6.jpg";
import * as React from 'react';
export default {
  faFacebook,
  faGithub,
  faInstagram,
  faTwitter,
  faDownload,
  faSave,
  faUpload,
  FontAwesomeIcon,
  loremIpsum,
  pic1,
  pic2,
  pic3,
  pic4,
  pic5,
  pic6,
  React
};