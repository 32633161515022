import { faFacebook, faGithub, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faDownload, faSave, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loremIpsum } from "react-lorem-ipsum";
import * as React from 'react';
export default {
  faFacebook,
  faGithub,
  faInstagram,
  faTwitter,
  faDownload,
  faSave,
  faUpload,
  FontAwesomeIcon,
  loremIpsum,
  React
};